import { Component } from "react";

import hexToHSL from '../utils/hexToHSL'

import * as styles from '../pages/pause/styles/pause.module.scss'
class ScrollDetector extends Component {
	constructor(props) {
		super(props)
		this.props = props
		this.classNameToSeek = styles.headerWrapper
	}
	
	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll)
		window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.handleScroll)
	}
	
	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
		window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', this.handleScroll)
	}
	
	handleScroll = () => {
		const swoosh = document.getElementsByClassName(this.classNameToSeek)[0]
		const size = swoosh.getBoundingClientRect()
		const percentage = 1 - Math.max(0, Math.min(1, size.bottom / size.height))
		
		const style = getComputedStyle(document.documentElement)
		const primary = hexToHSL(style.getPropertyValue('--primary-background').trim())
		const secondary = hexToHSL(style.getPropertyValue('--secondary-background').trim())
		
		let primaryHsl = primary.match(/\d+/g)
		let secondaryHsl = secondary.match(/\d+/g)

		if (primaryHsl === null) {
			return
		}

		if (primaryHsl[0] === '0') {
			primaryHsl[0] = secondaryHsl[0]
		}
		
		const result = secondaryHsl.map((element, index) => {
			const r = (parseFloat(element) * percentage)
			+ (parseFloat(primaryHsl[index]) * (1 - percentage))
			return r
		})
		
		const resultString = "hsl(" + result[0] + ", " + result[1] + "%, " + result[2] + "%)"
		
		document
			.querySelectorAll('meta[name="theme-color"]')
			.forEach((selector) => {
				selector.setAttribute('content', resultString)
			})
	}
	
	render() {
		return null;
	}
}

export default ScrollDetector