import React from 'react'
import { Helmet } from 'react-helmet'
import config from '../../config/site-config'

const SocialCard = ({title, description, image, isLargeImage=false}) => {
  title = title || config.siteTitle
  image = image || config.siteFavicon256
  image = image.startsWith("/") ? config.siteUrl + image : image

  const twitter = config.social["twitter"].username

  return (
    <Helmet>
      
      {/* <!-- Twitter Cards --> */}
      <meta name="twitter:title" content={title} />
      { description && <meta name="twitter:description" content={description} /> }
      <meta name="twitter:card" content={isLargeImage ? "summary_large_image" : "summary"} />
      <meta name="twitter:site" content={twitter} />
      <meta name="twitter:creator" content={twitter} />
      <meta name="twitter:image" content={image} />

      {/* <!-- Facebook Open Graph --> */}
      <meta property="og:title" content={title} />
      { description && <meta property="og:description" content={description} /> }
      <meta property="og:type" content="article" />
      <meta property="og:site_name" content={config.siteTitle} />
      <meta property="og:image" content={image} />
		
		{/* <!-- Mastodon --> */}
		<meta name="fediverse:creator" content="@harshil@mastodon.social" />
		
    </Helmet>
  )
}

export default SocialCard
