// extracted by mini-css-extract-plugin
export var appIcon = "pause-module--appIcon--xhSXt";
export var appleLogo = "pause-module--appleLogo--mk8+O";
export var backToTheHomepage = "pause-module--backToTheHomepage--RC2Qo";
export var breakImageWrapper = "pause-module--breakImageWrapper--Ad4yc";
export var breakSection = "pause-module--breakSection--pB1hU";
export var breakText = "pause-module--breakText--VIcRg";
export var content = "pause-module--content--91+mw";
export var feature = "pause-module--feature--+YXI+";
export var featureIcon = "pause-module--featureIcon--6y2Jb";
export var featureList = "pause-module--featureList--7OKsA";
export var header = "pause-module--header--E4Ll7";
export var headerWrapper = "pause-module--headerWrapper--aItTv";
export var info = "pause-module--info--oO4Mz";
export var lede = "pause-module--lede--EpGv5";
export var preferences = "pause-module--preferences--KRx9e";
export var preferencesImageWrapper = "pause-module--preferencesImageWrapper--zDR2M";
export var preferencesText = "pause-module--preferencesText--1FijA";
export var privacyContactHeader = "pause-module--privacyContactHeader--1Mba6";
export var purchaseBadgeCallout = "pause-module--purchaseBadgeCallout--WKQbU";
export var purchaseBadgeText = "pause-module--purchaseBadgeText--I0zqZ";
export var purchaseBadgeTitle = "pause-module--purchaseBadgeTitle--f7JXU";
export var purchaseLink = "pause-module--purchaseLink--KoPAE";
export var requirements = "pause-module--requirements--j+a6L";