import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import Head from '../../components/head'
import SocialCard from '../../components/social-card'
import ScrollDetector from '../../components/pauseScrollDetector'

import CardImage from './assets/card.png'

import Icon from './assets/pause.png'
import AppleLogo from './assets/appleLogo.svg'

import Break from './assets/break.png'

import Preferences from './assets/preferences.png'
import PreferencesDark from './assets/preferencesDark.png'
import Timers from './assets/timers.svg'
import Flow from './assets/flow.svg'
import Suggestions from './assets/suggestions.svg'

import Notifications from './assets/notifications.svg'
import Mac from './assets/mac.svg'
import Shortcuts from './assets/shortcuts.svg'
import Espanol from './assets/espanol.svg'

import * as styles from './styles/pause.module.scss'

const Pause = () => (
	<div className={styles.content}>
		
		<Helmet>
			<html data-style="pause" />
			
			<link rel="icon" type="image/png" sizes="16x16" href="/pause/favicons/16x16.png" />
			<link rel="icon" type="image/png" sizes="32x32" href="/pause/favicons/32x32.png" />
			<link rel="icon" type="image/png" sizes="48x48" href="/pause/favicons/48x48.png" />
			<link rel="icon" type="image/png" sizes="64x64" href="/pause/favicons/64x64.png" />
			<link rel="icon" type="image/png" sizes="192x192" href="/pause/favicons/192x192.png" />
			<link rel="apple-touch-icon" sizes="120x120" href="/pause/favicons/touch-120x120.png" />
			<link rel="apple-touch-icon-precomposed" sizes="152x152" href="/pause/favicons/touch-152x152.png" />
			<link rel="apple-touch-icon-precomposed" sizes="167x167" href="/pause/favicons/touch-167x167.png" />
			<link rel="apple-touch-icon-precomposed" sizes="180x180" href="/pause/favicons/touch-180x180.png" />
			
			<meta name="theme-color" content="hsl(208, 20%, 99%)" media="(prefers-color-scheme: light)" />
			<meta name="theme-color" content="hsl(208, 90%, 19%)" media="(prefers-color-scheme: dark)" />
		</Helmet>
		
		<SocialCard 
			title="Pause"
			image={CardImage}
			isLargeImage={true}
		/>
		
		<Head title="Pause" />
		
		<ScrollDetector />
		
		<div className={styles.headerWrapper}>
			
			<section className={styles.header}>
				
				<div className={styles.appIcon}>
					<img src={Icon} />
				</div>
				
				<div>
					<h1>Pause</h1>
					<p className={styles.lede}>A Mac app that<br/> reminds you to rest.</p>
					<a 
						href="https://apps.apple.com/us/app/pause-take-a-break/id1599313358"
						className={styles.purchaseLink}
						title="Download Pause"
						target="_blank"
					>
						<AppleLogo className={styles.appleLogo} />
						<div className={styles.purchaseBadgeText}>
							<span className={styles.purchaseBadgeCallout}>Download on the</span>
							<span className={styles.purchaseBadgeTitle}>Mac App Store</span>
						</div>
					</a>
				</div>
				
			</section>
			
		</div>
		
		<div className={styles.breakSection}>
			<div className={styles.breakText}>
				<p>
					Pause periodically reminds you to take a break, and step away from the computer.
				</p>
			</div>
			<div className={styles.breakImageWrapper}>
				<img src={Break} />
			</div>
		</div>
		
		<div className={styles.preferences}>
			<div className={styles.preferencesText}>
				<div className={styles.feature}>
					<Timers className={styles.featureIcon} />
					<p>Customise your work and break durations</p>
				</div>

				<div className={styles.feature}>
					<Flow className={styles.featureIcon}/>
					<p>Enable Flow to work without distractions</p>
				</div>

				<div className={styles.feature}>
					<Suggestions className={styles.featureIcon}/>
					<p>Set custom activity suggestions</p>
				</div>
			</div>
			
			<div className={styles.preferencesImageWrapper}>
				<picture>
					<source srcSet={PreferencesDark} media="(prefers-color-scheme: dark)" />
					<img src={Preferences} />
				</picture>
			</div>
			
		</div>
		
		<section className={styles.featureList}>
			<h2>And much more…</h2>

			<div className={styles.feature}>
				<Notifications className={styles.featureIcon} />
				<p>Multiple break notification styles</p>
			</div>
			
			<div className={styles.feature}>
				<Mac className={styles.featureIcon}/>
				<p>Support for macOS Ventura</p>
			</div>
			
			<div className={styles.feature}>
				<Shortcuts className={styles.featureIcon}/>
				<p>Siri Shortcuts</p>
			</div>

			<div className={styles.feature}>
				<Espanol className={styles.featureIcon}/>
				<p>Fully Localised in Spanish</p>
			</div>
		</section>

		<section className={styles.info}>
			<h3>System Requirements</h3>
			<p>macOS Big Sur 11.0 or newer</p>

			<p><Link to="/pause/privacy">Privacy Policy</Link></p>
			
			<p>Made by <a href="/">Harshil Shah</a></p>
		</section>
	</div>
)

export default Pause
	